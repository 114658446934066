import { default as _91id_93lxTmz5zzzSMeta } from "/tmp/8dcd412c96ce7fc/pages/applicant/[id].vue?macro=true";
import { default as _91id_93VkJpyIAbXPMeta } from "/tmp/8dcd412c96ce7fc/pages/apply/[id].vue?macro=true";
import { default as _91id_93SPoPU4MBSEMeta } from "/tmp/8dcd412c96ce7fc/pages/apply/perfil/[id].vue?macro=true";
import { default as thankspoKEti2p6lMeta } from "/tmp/8dcd412c96ce7fc/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93C5vqdlnYTgMeta } from "/tmp/8dcd412c96ce7fc/pages/articles/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93gnGBXcJ7KNMeta } from "/tmp/8dcd412c96ce7fc/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotEpSJqL8XdPMeta } from "/tmp/8dcd412c96ce7fc/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93ZSzva7LVDCMeta } from "/tmp/8dcd412c96ce7fc/pages/auth/signin/[...slug].vue?macro=true";
import { default as indextbnb3PbsK6Meta } from "/tmp/8dcd412c96ce7fc/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93ClhN284iViMeta } from "/tmp/8dcd412c96ce7fc/pages/auth/signup/[email].vue?macro=true";
import { default as indexWHZgk0yXv1Meta } from "/tmp/8dcd412c96ce7fc/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_93UlyytFiByhMeta } from "/tmp/8dcd412c96ce7fc/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cR6Yi2zpQFMeta } from "/tmp/8dcd412c96ce7fc/pages/biblioteca/[...slug].vue?macro=true";
import { default as indexJnEIEkve5xMeta } from "/tmp/8dcd412c96ce7fc/pages/biblioteca/index.vue?macro=true";
import { default as jobsdKYetozZaQMeta } from "/tmp/8dcd412c96ce7fc/pages/company/[id]/jobs.vue?macro=true";
import { default as indexdk5JsYlHnFMeta } from "/tmp/8dcd412c96ce7fc/pages/company/index.vue?macro=true";
import { default as onboardingUchb2xeSR9Meta } from "/tmp/8dcd412c96ce7fc/pages/company/onboarding.vue?macro=true";
import { default as gestao_pessoasoyFvPzeRinMeta } from "/tmp/8dcd412c96ce7fc/pages/gestao_pessoas.vue?macro=true";
import { default as indexLmm6OHEVfnMeta } from "/tmp/8dcd412c96ce7fc/pages/index.vue?macro=true";
import { default as _91applicant_93IwtC7A2ZwmMeta } from "/tmp/8dcd412c96ce7fc/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as index9oTVGEI9ZxMeta } from "/tmp/8dcd412c96ce7fc/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicateRGa1ZnSCcvMeta } from "/tmp/8dcd412c96ce7fc/pages/job/[job]/duplicate.vue?macro=true";
import { default as indexn8ZPUpKMyVMeta } from "/tmp/8dcd412c96ce7fc/pages/job/[job]/index.vue?macro=true";
import { default as archivedwB7XL7HT6dMeta } from "/tmp/8dcd412c96ce7fc/pages/job/archived.vue?macro=true";
import { default as closedHntn8g3ldJMeta } from "/tmp/8dcd412c96ce7fc/pages/job/closed.vue?macro=true";
import { default as newL9zHJy3cHnMeta } from "/tmp/8dcd412c96ce7fc/pages/job/new.vue?macro=true";
import { default as openedX2bjWrVsHRMeta } from "/tmp/8dcd412c96ce7fc/pages/job/opened.vue?macro=true";
import { default as pausedZpwSuZYKRVMeta } from "/tmp/8dcd412c96ce7fc/pages/job/paused.vue?macro=true";
import { default as onboardingKIfGfY9jUUMeta } from "/tmp/8dcd412c96ce7fc/pages/onboarding.vue?macro=true";
import { default as pesquisasbwq6DExsDZMeta } from "/tmp/8dcd412c96ce7fc/pages/pesquisas.vue?macro=true";
import { default as index5U5jVDFh5vMeta } from "/tmp/8dcd412c96ce7fc/pages/plans/index.vue?macro=true";
import { default as settingswXlJQUOmuUMeta } from "/tmp/8dcd412c96ce7fc/pages/settings.vue?macro=true";
import { default as indexuLo32CBLq9Meta } from "/tmp/8dcd412c96ce7fc/pages/thanks/index.vue?macro=true";
import { default as indexMedl7uEd8MMeta } from "/tmp/8dcd412c96ce7fc/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93lxTmz5zzzSMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/applicant/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/apply/perfil/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/apply/thanks.vue").then(m => m.default || m)
  },
  {
    name: "articles-slug",
    path: "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93C5vqdlnYTgMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/password/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93ZSzva7LVDCMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/signin/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indextbnb3PbsK6Meta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/signin/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/signup/[email].vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexWHZgk0yXv1Meta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/auth/verifyemail/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "biblioteca-slug",
    path: "/biblioteca/:slug(.*)*",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/biblioteca/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "biblioteca",
    path: "/biblioteca",
    meta: indexJnEIEkve5xMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/biblioteca/index.vue").then(m => m.default || m)
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dcd412c96ce7fc/pages/company/[id]/jobs.vue").then(m => m.default || m)
  },
  {
    name: "company",
    path: "/company",
    meta: indexdk5JsYlHnFMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingUchb2xeSR9Meta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/company/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "gestao_pessoas",
    path: "/gestao_pessoas",
    meta: gestao_pessoasoyFvPzeRinMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/gestao_pessoas.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexLmm6OHEVfnMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93IwtC7A2ZwmMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/[job]/applicants/[applicant].vue").then(m => m.default || m)
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: index9oTVGEI9ZxMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/[job]/applicants/index.vue").then(m => m.default || m)
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicateRGa1ZnSCcvMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/[job]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexn8ZPUpKMyVMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/[job]/index.vue").then(m => m.default || m)
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedwB7XL7HT6dMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/archived.vue").then(m => m.default || m)
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closedHntn8g3ldJMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/closed.vue").then(m => m.default || m)
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newL9zHJy3cHnMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/new.vue").then(m => m.default || m)
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedX2bjWrVsHRMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/opened.vue").then(m => m.default || m)
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedZpwSuZYKRVMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/job/paused.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingKIfGfY9jUUMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "pesquisas",
    path: "/pesquisas",
    meta: pesquisasbwq6DExsDZMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/pesquisas.vue").then(m => m.default || m)
  },
  {
    name: "plans",
    path: "/plans",
    meta: index5U5jVDFh5vMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingswXlJQUOmuUMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexuLo32CBLq9Meta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/thanks/index.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: indexMedl7uEd8MMeta || {},
    component: () => import("/tmp/8dcd412c96ce7fc/pages/user/index.vue").then(m => m.default || m)
  }
]