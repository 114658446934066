export default defineNuxtPlugin((nuxtApp) => {
    if (!window) {
        return
    }

    const runtimeConfig = useRuntimeConfig();

    if(!runtimeConfig.public.beamerId) {
        return
    }

    window.beamer_config = {
        product_id: runtimeConfig.public.beamerId,
        selector: 'beamerBt',
        button_position: 'in-app',
        display_position: 'right-top',
		language: 'PT',		
    };

    const beamerURL = "https://app.getbeamer.com/js/beamer-embed.js";

    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.defer = "defer";
    script.src = beamerURL;
    head.appendChild(script);
});
